<!--
  功能：注册登录
  作者：licy
  邮箱：licy@briup.com
  时间：2021年01月22日 16:41:53
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="login">
    <div class="content">
      <div class="content_top">
        <!-- <img src="../assets/indexlogo@2x.png" /> -->
      </div>
      <div class="content_bottom">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="登录"
            name="login"
          >
            <div class="input_text">
              <el-input placeholder="请输入用户名">
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-s-custom"
                ></i>
              </el-input>
              <el-input placeholder="请输入密码">
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-s-opportunity"
                ></i>
              </el-input>
            </div>
            <div class="autoLogin">
              <input type="checkbox" />
              <span style="margin-left: 10px">7天内自动登录</span>
            </div>
            <div class="button">
              <button>登录</button>
            </div>
            <div class="orther">
              <span>快捷登录：</span>
              <i
                class="iconfont icon-weixin"
                aria-hidden="true"
              ></i>
              <i
                class="iconfont icon-QQ-circle-fill"
                aria-hidden="true"
              ></i>
            </div>
          </el-tab-pane>

          <el-tab-pane
            label="注册"
            name="register"
          >
            <div class="input_text">
              <el-input placeholder="请输入注册手机号">
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-s-custom"
                ></i>
              </el-input>
            </div>
            <div class="button">
              <button>点击按钮进行验证</button>
            </div>
            <div class="autoLogin">
              <input type="checkbox" />
              <span style="margin-left: 10px">我已阅读并同意<span style="color: #3eaf7c">《用户注册协议》</span></span>
            </div>

            <div class="orther">
              <span>快捷登录：</span>
              <i
                class="iconfont icon-weixin"
                aria-hidden="true"
              ></i>
              <i
                class="iconfont icon-QQ-circle-fill"
                aria-hidden="true"
              ></i>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data() {
    return {
      activeName: "login"
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  },
  // 以下是生命周期钩子
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    this.activeName = this.$route.query.data;
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style scoped>
.login >>> .icon-QQ-circle-fill {
  font-size: 23px;
}
.login {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  position: relative;
}
.content {
  width: 28%;
  height: 70%;
  /* background-color: teal; */
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  margin-top: 5%;
}
.content_top {
  width: 100%;
  height: 20%;
  /* background-color: thistle; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content_top img {
  display: block;
  width: 50%;
  height: 50%;
}
.content_bottom {
  width: 100%;
  height: 380px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 3px 0 rgba(213, 213, 213, 0.7);
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}
.login >>> .el-tabs__nav {
  margin-left: 30% !important;
}
.login >>> .el-tabs__item {
  font-size: 18px !important;
  font-weight: 400;
  padding: 0 43px;
  /* padding-left: 10px; */
}
.login >>> .el-tabs__item.is-active {
  color: #3eaf7c;
}
.login >>> .el-tabs__active-bar {
  background-color: #3eaf7c;
  width: 60px !important;
  left: -10px;
}
.login >>> .el-tabs__item:hover {
  color: #3eaf7c;
}
.input_text > div {
  margin-top: 10px;
  margin-bottom: 20px;
}
.input_text >>> .el-input__inner:focus {
  border-color: #3eaf7c;
}
.button {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.button button {
  display: block;
  margin: 0 auto;
  border: none;
  width: 100%;
  height: 100%;
  background-color: #3eaf7c;
  color: #fff;
  font-size: 16px;
  outline: none;
}
.orther {
  margin-top: 20px;
  text-align: center;
}
.orther i {
  font-size: 20px;
  color: #b5b9bd;
  margin: 10px;
}
</style>
